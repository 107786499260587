.MinigamesPage {
  background-color: rgb(129, 129, 169);
  text-align: center;
  font-size: 30px;
}

.gamebutton {
  margin-left: 20px;
  border: 3px solid black;
}

.gamebutton:hover {
  background: rgb(200, 244, 255);
  color: rgb(255, 0, 0);
}

.turns {
  text-align: center;
}
